// Requiert functions.isInViewport
// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js

import * as functions from 'partials/functions.js';

function FormValidation( args ) {
	let that = this;
	let validationCompleteEvent = new Event( 'validationcomplete' );
	args = args || {};

	args.submitSel = args.submitSel || 'button[type="submit"], input[type="submit"]';
	args.wrapFieldSel = args.wrapFieldSel || '.wrap-field';
	args.fieldOuterSel = args.fieldOuterSel || '.field-block';
	args.classInvalidTmp = args.classInvalidTmp || 'tmp-invalid';
	args.classHiddenTmp = args.classHiddenTmp || 'tmp-hidden';
	args.classVisible = args.classVisible || 'visible';
	args.classPrefix = args.classPrefix || 'validation-';
	args.classMessage = args.classMessage || 'message';
	args.classIcon = args.classIcon || 'icon';
	args.classIconValid = args.classIconValid || 'icon-checkmark-circle';
	args.classIconInvalid = args.classIconInvalid || 'icon-x-circle';
	args.messageTagName = args.messageTagName || 'aside';
	args.eventDelay = args.eventDelay || 600;
	args.smoothScroller = args.smoothScroller || window.smoothScroller || null;

	args.debug = args.debug || false;

	this.args = args;

	this.init = function() {
		that.i18n = window.bonesGlobals.i18n.validation;
		that.fileSizeUnits = window.bonesGlobals.i18n.fileSizeUnits;

		that.form = that.args.form;
		that.submitButtons = that.form.querySelectorAll( that.args.submitSel );
		that.fileInputs = that.form.querySelectorAll('input[type="file"]');

		that.setCustomValidity( that.form );

		// On désactive la validation normale pour que l'utilisateur ait le droit d'essayer de soumettre (et triggerer notre validation)
		that.form.noValidate = true;

		// On enregistre dans chaque champ ses éléments HTML importants et ses classes d'origine
		for( let thisEl of that.form.elements ) {
			// S'il fait partie d'un groupe de checkbox, on va garder seulement le fieldset
			if( 'checkbox' === thisEl.type && thisEl.closest( '.group-checkbox' ) !== null ) {
				continue;
			}

			thisEl.wrapField = thisEl.closest( that.args.wrapFieldSel );
			thisEl.fieldOuter = thisEl.closest( that.args.fieldOuterSel );

			if( thisEl.fieldOuter ) {
				thisEl.fieldOuter.classesAdded = [];
			}

			if( thisEl.wrapField ) {
				thisEl.message = that.createFieldMessage();
				thisEl.icon = that.createFieldIcon();
				thisEl.wrapField.append( thisEl.icon );
				thisEl.wrapField.append( thisEl.message );
			}
		}
	};

	this.isIterable = function( target ) {
		// Vaut pour null ET undefined
		if( target == null ) {
			return false;
		}

		return (
			target instanceof HTMLCollection
			|| target instanceof NodeList
			|| Array.isArray( target )
		);
	};



	// Actualise la validité d'un élément
	this.setCustomValidityForEl = function( target ) {
		target.isException = target.isException || {};
		target.customValidity = target.customValidity || { valid: true };

		// On ne le traite que si c'est un input/select/textarea. Fuck les fieldsets.
		if( target instanceof HTMLInputElement ) {
			// Commençons par valider nos règles custom.
			that.validateFileExtensionsForEl( target );
			that.validateFileInputNotEmptyForEl( target );
			that.validateFileSizeForEl( target );

		// On va gérer les groupe de checkbox dans des fieldset au besoin
		} else if( target instanceof HTMLFieldSetElement && target.classList.contains('at-least-one') ) {

			that.validateAtLeastOneCheckbox( target );
		}

		// Par défaut, c'est valide.
		target.customValidity.valid = true;

		// Préparons-nous un objet avec toutes les propriétés standard ou custom. Il va juste servir de guide pour itérer sur absolument toutes les propriétés
		let allValidity = {};

		for( let propertyName in target.validity ) {
			allValidity[propertyName] = target.validity[propertyName];
		}

		for( let propertyName in target.customValidity ) {
			allValidity[propertyName] = target.customValidity[propertyName];
		}

		// Sauf valid, vu qu'il est géré à la main dans la boucle
		delete allValidity.valid;

		// Faisons le tour des propriétés pour savoir si l'élément et le form sont globalement valides.
		// On va aussi propager l'invalidité d'un champ au formulaire.
		for( let propertyName in allValidity ) {
			// Si le form n'a pas cette propriété, donnons-lui. À false parce que le formulaire est tout valide par défaut.
			if( 'undefined' === typeof target.form.customValidity[propertyName] ) {
				target.form.customValidity[propertyName] = false;
			}

			// Copions l'objet validité standard, sauf si cet élément est une exception dans cette propriété (dans quel cas il a déjà la bonne valeur)
			if( ! target.isException[propertyName] ) {
				target.customValidity[propertyName] = target.validity[propertyName] || false;
			} else {
				if( that.args.debug ) {
					console.log( 'form-validation: target is an exception in property "'+propertyName+'". Value will stay with its old value:' );
					console.log( target.customValidity[propertyName] );
				}
			}

			// Pour chaque propriété, on décide si l'élément est valide
			if( target.customValidity[propertyName] ) {
				// Cette propriété a une erreur. Ça veut dire que ni le champ, ni le form ne sont valides.
				that.form.customValidity[propertyName] = true;
				target.customValidity.valid = false;
				that.form.customValidity.valid = false;
			}
		}

		if( that.args.debug ) {
			console.log('form-validation: Validated field:');
			console.log(target);
			console.log('form-validation: Current validity of field:');
			console.log(target.customValidity);
			console.log('form-validation: Current validity of form:');
			console.log(that.form.customValidity);
		}

	};

	// On met à jour la validité custom de la cible, ou de tous les éléments si on a reçu le form
	this.setCustomValidity = function( target ) {

		target.customValidity = target.customValidity || {};

		if( target instanceof HTMLInputElement || ( target instanceof HTMLFieldSetElement && target.classList.contains('group-checkbox') ) ) {
			// C'est un champ. Traitons-le
			that.setCustomValidityForEl( target );
		} else if( target instanceof HTMLFormElement && target.elements ) {
			// C'est le form. On lui prépare un objet de validité à remplir.
			target.customValidity = { valid: true };

			// Ensuite on fait le tour des champs. Eux vont décider si c'est valide ou pas.
			for( let thisEl of target.elements ) {
				that.setCustomValidityForEl( thisEl );
			}
		}
	};

	// La version HTML5 de base marche pas pantoute pour les types file.
	this.validateFileInputNotEmptyForEl = function( target ) {
		if( target.type && 'file' == target.type ) {
			target.isException.valueMissing = true;

			if( target.required ) {
				target.customValidity.valueMissing = ( target.files.length == 0 );

				if( that.args.debug ) {
					console.log('checking if file input is not empty for files ' + target.files);
					console.log('new customValidity.valueMissing value is:');
					console.log( target.customValidity.valueMissing );
				}
			} else {
				// C'est même pas requis. C'est forcément valide.
				target.customValidity.valueMissing = false;
			}
		} else {
			// C'est même pas un type file. On ne fait rien. La validation HTML5 s'en occupe.
		}
	};

	// Validation custom : le fichier est-il trop gros?
	this.validateFileSizeForEl = function( target ) {
		if( target.type && 'file' == target.type ) {
			target.isException.fileSizeOverflow = true;

			if( target.dataset.maxSize ) {
				target.customValidity.fileSizeOverflow = false;

				target.files.forEach( function( thisFile ) {
					if( thisFile.size > target.dataset.maxSize ) {
						target.customValidity.fileSizeOverflow = true;
					}
				} );

				if( that.args.debug ) {
					console.log('checking the size of files ' + target.files);
					console.log('new customValidity.fileSizeOverflow value is:');
					console.log( target.customValidity.fileSizeOverflow );
				}
			} else {
				// Y'a même pas de max. C'est forcément valide
				target.customValidity.fileSizeOverflow = false;
			}
		} else {
			// C'est même pas un type file. Rien à faire.
		}
	};

	this.validateAtLeastOneCheckbox = function( target ) {
		if( target.querySelectorAll( 'input[type="checkbox"]:checked' ).length === 0 ) {
			target.isException.atLeastOne = true;
			target.customValidity.atLeastOne = true;
		} else {
			target.customValidity.atLeastOne = false;
		}

	};

	// La validation HTML5 n'a aucun concept de vérification d'extensions. Occupons-nous-en.
	this.validateFileExtensionsForEl = function( target ) {
		if( that.args.debug ) {
			console.log( 'form-validation: Might check file extensions for target:' );
			console.log( target );
		}

		if( target.files ) {
			// Si c'est pas déjà fait, on enregistre un array des formats valides dans l'élément avant de vérifier
			if( 'undefined' === typeof target.acceptArray ) {
				let acceptStr;

				if( target.hasAttribute( 'accept' ) ) {
					acceptStr = target.getAttribute( 'accept' );

					if( acceptStr ) {
						target.acceptArray = acceptStr.split( ',' );
					} else {
						target.acceptArray = [];
					}
				} else {
					target.acceptArray = null;
				}

				if( that.args.debug ) {
					console.log( 'form-validation: set acceptArray:' );
					console.log( target.acceptArray );
				}
			}

			target.isException.fileExtensionMismatch = true;

			if( null === target.acceptArray ) {
				// null = pas de contrainte. C'est forcément valide.
				target.customValidity.fileExtensionMismatch = false;
			} else {
				// Un array = il y a des contraintes. Validons.

				if( target.files.length ) {
					// Faisons le tour des fichiers.
					target.files.forEach( function( thisFile ) {
						let fileExtension = '.' + thisFile.name.split( '.' ).pop().toLowerCase();
						let fileIsValid = target.acceptArray.includes( fileExtension );

						target.customValidity.fileExtensionMismatch = ! fileIsValid;

						if( that.args.debug ) {
							console.log( 'form-validation: Checked file extensions for file ' + thisFile.name );
							console.log( 'form-validation: New customValidity.fileExtensionMismatch value is:' );
							console.log( target.customValidity.fileExtensionMismatch );
						}

						/*if( ! fileIsValid ) {
							target.value = '';
						}*/

					} );
				} else {
					// Y'a aucun fichier dans le champ! C'est forcément valide.
					target.customValidity.fileExtensionMismatch = false;
				}
			}
		}
	};

	this.updateClasses = function( whatToUpdate ) {
		if( whatToUpdate == that.form ) {
			whatToUpdate = whatToUpdate.elements;
		}

		if( that.isIterable( whatToUpdate ) ) {
			for( let thisEl of whatToUpdate ) {
				that.updateClassesForEl( thisEl );
			}
		} else {
			that.updateClassesForEl( whatToUpdate );
		}
	};

	this.updateClassesForEl = function( thisEl ) {
		that.resetClassesForEl( thisEl );

		if( thisEl.fieldOuter ) {
			let classesForOuter = [];

			for( let propertyName in thisEl.customValidity ) {
				let classFinalPart;

				// Une classe par propriété valide ou pas sur le parent. (Champ valide globalement = propriété "valid")
				if( thisEl.customValidity[ propertyName ] ) {
					classFinalPart = 'true';
				} else {
					classFinalPart = 'false';
				}

				if( propertyName ) {
					classesForOuter.push( that.args.classPrefix + propertyName + '-' + classFinalPart );
				}
			}

			thisEl.fieldOuter.classesAdded = classesForOuter;
			thisEl.fieldOuter.classList.add( ...classesForOuter );
		}

		if( thisEl.icon ) {
			let classesForIcon = [];
			// Changement de la classe de l'icône
			if( that.checkCustomValidity( thisEl ) ) {
				classesForIcon.push( that.args.classIconValid );
			} else {
				classesForIcon.push( that.args.classIconInvalid );
			}

			thisEl.icon.theIcon.classesAdded = classesForIcon;
			thisEl.icon.theIcon.classList.add( ...classesForIcon );
		}
	};

	this.resetClassesForEl = function( thisEl ) {
		if( thisEl.fieldOuter && thisEl.fieldOuter.classesAdded ) {
			thisEl.fieldOuter.classList.remove( ...thisEl.fieldOuter.classesAdded );
		}

		if( thisEl.icon && thisEl.icon.theIcon.classesAdded ) {
			thisEl.icon.theIcon.classList.remove( ...thisEl.icon.theIcon.classesAdded );
		}
	};

	this.updateMessages = function( whatToUpdate) {
		let includeGlobal = false;

		if( whatToUpdate == that.form ) {
			includeGlobal = true;
			whatToUpdate = whatToUpdate.elements;
		}

		if( that.isIterable( whatToUpdate ) ) {
			for( let thisEl of whatToUpdate ) {
				that.updateMessageForEl( thisEl );
			}
		} else {
			that.updateMessageForEl( whatToUpdate );
		}

		if( includeGlobal ) {
			that.updateMessageGlobal();
		}
	};

	this.updateMessageForEl = function( thisEl ) {
		if( thisEl.message ) {
			let errorStrings = [];
			for( let messageType in thisEl.customValidity ) {
				if( thisEl.customValidity[ messageType ] ) {
					let thisErrorString = that.i18n[messageType] || null;
					let insertString = '';

					if( thisErrorString ) {

						switch( messageType ) {
							case 'typeMismatch':
								thisErrorString = that.i18n[messageType][thisEl.type] || that.i18n[messageType].default;
								break;
							case 'rangeOverflow':
								insertString = '<strong class="number">' + thisEl.max + '</strong>';
								break;
							case 'rangeUnderflow':
								insertString = '<strong class="number">' + thisEl.min + '</strong>';
								break;
							case 'stepMismatch':
								insertString = '<strong class="number">' + thisEl.step + '</strong>';
								break;
							case 'tooLong':
								insertString = '<strong class="number">' + thisEl.maxLength + '</strong>';
								break;
							case 'tooShort':
								insertString = '<strong class="number">' + thisEl.minLength + '</strong>';
								break;
							case 'fileExtensionMismatch':
								( function() {
									let extensionList = document.createElement( 'ul' );
									extensionList.classList.add( that.args.classPrefix + that.args.classMessage + '-sublist' );

									thisEl.acceptArray.forEach( function( thisExt ) {
										let extensionEl = document.createElement( 'li' );
										extensionEl.classList.add( that.args.classPrefix + that.args.classMessage + '-sublist-el' );
										extensionEl.innerHTML = thisExt.replace( /^\./, '' );
										extensionList.append( extensionEl );
									} );

									insertString = extensionList.outerHTML;
								}) ();
								break;
							case 'fileSizeOverflow':
								insertString =
									'<strong class="number">'
										+ '<span class="the-number">'
											// Arrondissement à 2 chiffres après la virgule, mais seulement si nécessaire.
											+ ( Math.round( thisEl.dataset.maxSize / that.fileSizeUnits.mb.value * 100 ) / 100 )
										+ '</span>'
										+ ' <span class="unit">'
											+ that.fileSizeUnits.mb.nameShort
										+ '</span>'
									+ '</strong>'
								;
								break;
							case 'atLeastOne' :
								insertString = that.i18n[messageType] || that.i18n[messageType].default;
								break
							default:
								// Rien à remplacer pour les autres types
								break;
						}

						if( insertString ) {
							thisErrorString = thisErrorString.replace( '%s', insertString );
						}

						errorStrings.push( thisErrorString );
					}
				}
			}

			thisEl.icon.classList.add( that.args.classVisible );

			if( errorStrings.length > 0 ) {
				let messageList = thisEl.message.querySelector( '.' + that.args.classPrefix + that.args.classMessage + '-list' );
				let frag = new DocumentFragment();

				errorStrings.forEach( function( thisErrorString ) {
					let listEl = document.createElement( 'li' );
					listEl.classList.add( that.args.classPrefix + that.args.classMessage + '-list-el' );
					listEl.innerHTML = thisErrorString;
					frag.append( listEl );
				} );

				if( thisEl.message.classList.contains( that.args.classVisible ) ) {
					// Si le message est déjà visible, on le cache le temps de changer les strings
					thisEl.message.classList.add( that.args.classHiddenTmp );
					thisEl.icon.classList.add( that.args.classHiddenTmp );

					thisEl.message.addEventListener( 'transitionend', function messageDisappeared() {
						// Le message a fini de disparaître ! On peut changer la string puis lancer la réapparition.
						that.setMessageListHtml( messageList, frag );
						thisEl.message.classList.remove( that.args.classHiddenTmp );
						thisEl.icon.classList.remove( that.args.classHiddenTmp );
						thisEl.message.removeEventListener( 'transitionend', messageDisappeared );
					} );
				} else {
					that.setMessageListHtml( messageList, frag );
				}

				thisEl.message.classList.add( that.args.classVisible );
			} else {
				// Aucune erreur ! On cache la bulle.
				thisEl.message.classList.remove( that.args.classVisible );
			}
		}
	};

	this.setMessageListHtml = function( messageList, newHtml ) {
		while( messageList.lastChild ) {
			messageList.removeChild( messageList.lastChild );
		}
		messageList.append( newHtml );
	};

	
	this.updateMessageGlobal = function() {};
	

	this.createFieldMessage = function() {
		let messageEl;
		let messageInnerEl;
		let messageListEl;

		messageEl = document.createElement( that.args.messageTagName );
		messageEl.classList.add( that.args.classPrefix + that.args.classMessage );
		messageEl.setAttribute( 'role', 'alert' );

		messageInnerEl = document.createElement( 'div' );
		messageInnerEl.classList.add( that.args.classPrefix + that.args.classMessage + '-inner' );

		messageListEl = document.createElement( 'ul' );
		messageListEl.classList.add( that.args.classPrefix + that.args.classMessage + '-list' );

		messageInnerEl.append( messageListEl );
		messageEl.append( messageInnerEl );
		return messageEl;
	};

	this.createFieldIcon = function() {
		let iconEl;
		let iconInnerEl;
		let theIconEl;

		iconEl = document.createElement( 'div' );
		iconEl.classList.add( that.args.classPrefix + that.args.classIcon );

		iconInnerEl = document.createElement( 'div' );
		iconInnerEl.classList.add( that.args.classPrefix + that.args.classIcon + '-inner' );

		theIconEl = document.createElement( 'div' );
		theIconEl.classList.add( that.args.classPrefix + that.args.classIcon + '-the-icon' );

		iconEl.theIcon = theIconEl;
		iconEl.iconInnerEl = iconInnerEl;

		iconInnerEl.append( theIconEl );
		iconEl.append( iconInnerEl );

		iconEl.theIcon.classesAdded = [];
		return iconEl;
	};

	this.goToFirstInvalid = function() {
		let firstInvalid = that.getFirstInvalid();

		if( firstInvalid ) {
			if( that.args.smoothScroller && ! functions.isInViewport( firstInvalid, null, false, that.args.smoothScroller.parent ) ) {
				that.args.smoothScroller.scrollTo({
					target: firstInvalid.fieldOuter,
					align: {
						x: 'center',
						y: 'center',
					},
				});

				that.args.smoothScroller.parent.addEventListener( 'smoothscrollend', function focusFirstInvalid() {
					firstInvalid.focus();
					that.args.smoothScroller.parent.removeEventListener( 'smoothscrollend', focusFirstInvalid );
				} );
			} else {
				firstInvalid.focus();
			}
		}
	};

	this.getFirstInvalid = function() {
		let firstInvalid = null;

		for( let thisEl of that.form.elements ) {
			if( ! that.checkCustomValidity( thisEl ) ) {
				firstInvalid = thisEl;
				break;
			}
		}

		return firstInvalid;
	};

	this.doValidation = function( whatToValidate = that.form ) {
		if( ! whatToValidate || ! whatToValidate.alreadyValidated ) {
			if( that.args.debug ) {
				console.log('___________________________________________________________________');
				console.log('New validation with:');
				console.log( whatToValidate );
			}

			that.setCustomValidity( whatToValidate );

			that.updateClasses( whatToValidate );
			that.updateMessages( whatToValidate );

			whatToValidate.dispatchEvent( validationCompleteEvent );
		}

		if( whatToValidate.alreadyValidated ) {
			whatToValidate.alreadyValidated = false;
		}
	};

	// Un checkValidity qui prend en compte les règles custom
	this.checkCustomValidity = function( target ) {
		return target.customValidity.valid;
	};

	// Initialisation

	this.init();

	if( this.form ) {
		this.form._FormValidation = that;
	} else {
		return [];
	}


	// Évènements

	for( let thisEl of this.form.elements ) {
		let inputTimeout;

		// Empêcher les bulles de validation du navigateur
		thisEl.addEventListener( 'invalid', function( event ) {
			event.preventDefault();
		} );

		// Mettre à jour chaque champ quand on fait quelque chose avec
		[ 'input', 'change' ].forEach( function( eventName ) {
			thisEl.addEventListener( eventName, function() {
				// On ne déclenche la validation qu'après un court délai (sinon une même action pourrait valider plusieurs fois, et ça flasherait quand on tape)
				if( inputTimeout ) {
					clearTimeout( inputTimeout );
				}

				inputTimeout = setTimeout( function() {
					that.doValidation( thisEl );
				}, that.args.eventDelay );
			} );
		} );
	}

	// Empêcher de soumettre si le form est invalide
	this.form.addEventListener( 'submit', function( event ) {
		that.doValidation();

		if( ! that.checkCustomValidity( event.target ) ) {
			event.preventDefault();
			that.goToFirstInvalid();

			that.form.classList.add( that.args.classInvalidTmp );

			if( that.submitButtons.length > 0 ) {
				that.submitButtons[0].addEventListener( 'animationend', function invalidAnimEnd( event ) {
					that.form.classList.remove( that.args.classInvalidTmp );
					event.target.removeEventListener( 'animationend', invalidAnimEnd );
				} );
			}
		} else {
			// Tout est OK! Bloquons le bouton Submit pour éviter une multi-soumission.
			that.submitButtons.forEach( function( thisButton ) {
				thisButton.disabled = true;
			} );
		}
	} );
}

export default FormValidation;