/*** Importations ******************************************************************************/

// Polyfills

// import 'mdn-polyfills/NodeList.prototype.forEach';
// import 'mdn-polyfills/Element.prototype.matches';
// import 'mdn-polyfills/Element.prototype.closest';
// import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.remove';
// import 'mdn-polyfills/Object.assign';
// import 'partials/stepup-stepdown-polyfill';

// import 'classlist-polyfill';
// import 'unfetch/polyfill';
// import 'focus-within-polyfill';
import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import smoothscroll from 'smoothscroll-polyfill';

// Libraries

import InfiniteScroll from 'infinite-scroll';
import LazyLoad from 'vanilla-lazyload/dist/lazyload.esm.js';
// import * as basicLightbox from 'basiclightbox/src/scripts/main.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';
import GLightbox from 'glightbox/dist/js/glightbox.js';
import Glide from '@glidejs/glide/dist/glide.esm.js';

// Particles.js est incompatible avec le mode strict. Il est enqueué dans Bones.


// Scripts Nubee

import * as functions from 'partials/functions.js';
import GoogleMap from 'partials/google-map.js';
import MobileMenu from 'partials/mobile-menu.js';
// import PageMenu from 'partials/page-menu.js';
import toggleSearchForm from 'partials/toggle-search-form.js';
import imgCheck from 'partials/img-check.js';
// import AjaxPopup from 'partials/ajax-popup.js';
import GLightboxNubee from 'partials/glightbox-nubee.js';
// import NubeeLightbox from 'partials/nubee-lightbox.js';
import placeholderLabelUpdate from 'partials/placeholder-label.js';
import FormValidation from 'partials/form-validation.js';
// import PrettyFileInput from 'partials/pretty-file-input.js';
// import SwipeFinger from 'partials/swipe-finger.js';

import * as cookiesBar from 'partials/cookies-bar.js';

(function() {


	/*** Application des polyfills *****************************************************************/

	(function() {
		objectFitImages();
		smoothscroll.polyfill();

		if( 'function' != typeof window.Event ) {
			window.Event = CustomEvent;
		}
	}) ();


	/*** Variables globales ************************************************************************/

	window.vWidth = document.documentElement.clientWidth;
	window.vHeight = document.documentElement.clientHeight;

	if( window.vWidth >= 800 ) {
		let pageMenuEl = document.querySelector('.page-menu');
		window.fixedElHeight = pageMenuEl ? pageMenuEl.clientHeight : 0;
	} else {
		window.fixedElHeight = 0;
	}

	window.fixedElWidth = 0;

	window.vWidthExceptFixed = document.documentElement.clientWidth - fixedElWidth;
	window.vHeightExceptFixed = window.vHeight - fixedElHeight;
	window.curScroll = {
		top: document.documentElement.scrollTop || document.body.scrollTop || 0,
		left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
		topExceptFixed: ( document.documentElement.scrollTop || document.body.scrollTop || 0 ) + window.fixedElHeight,
		leftExceptFixed: ( document.documentElement.scrollLeft || document.body.scrollLeft || 0 ) + window.fixedElWidth,
	};
	window.vCenter = {
		x: window.curScroll.left + ( window.vWidth / 2 ),
		y: window.curScroll.top + ( window.vHeight / 2 ),
		xExceptFixed: window.curScroll.left + ( window.vWidthExceptFixed / 2 ),
		yExceptFixed: window.curScroll.top + ( window.vHeightExceptFixed / 2 ),
	};
	window.ga = window.ga || window._gaq || null;
	window.lastWindowOpened = 0;

	// Chaîne traduisible : window.bonesGlobals.i18n.monPlugin.nomDeChaine


	/*** Mise à jour des mesures de l'écran au resize *********************************************/

	window.addEventListener( 'resize', function() {
		window.vWidth = document.documentElement.clientWidth;
		window.vHeight = document.documentElement.clientHeight;
		window.curScroll.top = document.documentElement.scrollTop || document.body.scrollTop;
		window.vCenter.y = window.curScroll.top + ( window.vHeight / 2 );
	} );


	/*** Les liens avec ancre qui mènent vers la même page deviennent seulement des ancres ****************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linksWithHash = document.querySelectorAll('a[href*="#"]');

		linksWithHash.forEach( function( thisLink ) {
			if( functions.urlsAreSamePage({ urls: [ thisLink.href, window.location.href ] }) ) {
				thisLink.href = thisLink.hash;
			}
		} );
	}) ();


	/*** Défiler doucement les liens ancres ****************************************************************/
	// Requiert la fonction functions.smoothScrollTo
	// Ajuster la variable globale fixedElHeight

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			document.querySelectorAll('a[href^="#"]:not([href="#"])').forEach( function( thisEl ) {
				thisEl.addEventListener( 'click', function( event ) {
					if( 1 == event.which && ! this.classList.contains('no-smooth-scroll') ) {
						let theHash;

						event.preventDefault();
						theHash = this.getAttribute('href');
						functions.smoothScrollTo( theHash );
					}
				} );
			});
		}
	}) ();


	/*** Ne rien faire au clic sur un lien vide (#) *********************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest('a');
				let theHref = linkClicked ? linkClicked.getAttribute('href') : null;

				if( linkClicked && ( ! theHref || '#' == theHref ) ) {
					event.preventDefault();
				}
			}
		} );
	}) ();


	/*** Défiler doucement au chargement d'une URL avec ancre ***********************************************/
	// Requiert la fonction functions.smoothScrollTo

	(function() {
		if( 'function' == typeof functions.smoothScrollTo ) {
			let origHash = window.location.hash;

			if( origHash ) {
				// Blocage du jump du navigateur par défaut
				setTimeout( function() {
					window.location.hash = '';
					functions.removeHash();
				}, 1 )

				window.addEventListener( 'load', function() {
					functions.smoothScrollTo( document.querySelector( origHash ) );
				});
			}

		}
	}) ();


	/*** Images et backgrounds différents selon la taille de l'écran *****************************/
	// Requiert img-check.js

	(function() {
		if( 'function' == typeof imgCheck ) {
			window.addEventListener( 'resize', imgCheck );
			imgCheck();
		}
	}) ();


	/*** Lazy load des images et backgrounds ****************************************************************/
	// Requiert vanilla-lazyload

	(function() {
		window.siteLazyLoad = new LazyLoad({
			elements_selector: '.lazy',
			class_loading: 'lazy-loading',
			class_loaded: 'lazy-loaded',
			class_error: 'lazy-error',
		});
	}) ();


	/*** Sécurité pour les liens "nouvel onglet" ***********************************************************/


	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which || 2 == event.which ) {
				let linkClicked = event.target.closest('a');
				let linkTarget = linkClicked ? linkClicked.getAttribute('target') : null;
				let newWindow;
				let targetRegex = new RegExp( /^(_blank|_new)$/, 'i' )

				if( linkTarget && targetRegex.test( linkTarget ) ) {
					let linkUrl = linkClicked.getAttribute('href');

					event.preventDefault();
					newWindow = window.open( linkClicked.getAttribute('href'), 'targetBlankWindow_' + window.lastWindowOpened );

					++ window.lastWindowOpened;

					if( newWindow ) {
						newWindow.opener = null;
					}
				}
			}
		} );
	}) ();


	/*** Google Maps ***************************************************************************************/
	// Requiert google-map.js

	(function() {
		let mapParents = document.querySelectorAll('.map-parent');
		let mapStyles = [];

		mapParents.forEach( function( thisParent ) {
			let map = new GoogleMap({
				mapParent: thisParent,
				styles: mapStyles,
			});
		} );
	}) ();


	/*** Antispam maison sur les commentaires *************************************************************/
	// À moins d'avoir activé le JS, la validation PHP refusera le commentaire.

	(function() {
		let commentForms = document.querySelectorAll('#commentform');

		commentForms.forEach( function( thisForm ) {
			thisForm.addEventListener( 'submit', function() {
				let legitInput = document.createElement( 'input' );
				legitInput.setAttribute( 'type', 'hidden' );
				legitInput.setAttribute( 'name', 'is_legit' );
				legitInput.value = 1;

				thisForm.appendChild( legitInput );
			} );
		} );
	}) ();


	/*** Validation de formulaire *************************************************************************/
	(function() {
		document.querySelectorAll( '.validate-form' ).forEach( function( thisForm ) {
			let formValidation = new FormValidation({
				form: thisForm,
				// debug: true,
			});
		} );
	}) ();

	(function() {
		document.querySelectorAll( '.hidden-file-input' ).forEach( function( thisInput ) {
			let labelContainer = thisInput.nextElementSibling.querySelector('.file-input-label');

			thisInput.addEventListener( 'change', function(){
				labelContainer.innerText = this.files[0].name ?? bonesGlobals.i18n.prettyFileInput.simple;
			} );
		} );
	}) ();

	/*** Placeholder labels - Un placeholder qui se tasse quand on écrit **********************************/
	// Requiert la classe sweet-form sur le formulaire
	// Requiert placeholder-label.js

	(function() {
		document.querySelectorAll('.has-placeholder-label input, .has-placeholder-label textarea').forEach( function( thisEl ) {
			placeholderLabelUpdate( thisEl );

			[ 'load', 'focus', 'blur', 'change', 'input' ].forEach( function( eventName ) {
				thisEl.addEventListener( eventName, placeholderLabelUpdate );
			} );
		} );
	}) ();


	/*** Retour en haut ***********************************************************************************/
	// Requiert functions.smoothScrollTo

	/*(function() {
		document.querySelector('.back-to-top').addEventListener( 'click', function() {
			if( 1 == event.which ) {
				functions.smoothScrollTo( document.body, 0 );
			}
		} );
	}) ();*/


	/*** Menu mobile maison *******************************************************************************/

	(function() {
		window.mobileMenu = new MobileMenu({
			menuSel: '.mobile-menu',
			buttonSel: '.mobile-menu-button',
			debug: false,
		});
	}) ();

	/*** Menu de page à hash qui se met à jour avec le scroll *********************************************/
	// Requiert page-menu.js
	// Ne pas oublier d'appeler window.pageMenu.update() dans le scrollInterval

	/*(function() {
		window.pageMenu = new PageMenu({
			menuSel: '.page-menu',
		});
	}) ();*/


	/*** Regroupement d'évènements à intervalles réguliers ************************************************/
	// Plus performant qu'un évènement scroll

	(function() {
		window.scrollInterval = setInterval(function() {
			let lastScroll = window.curScroll;

			window.curScroll = {
				top: document.documentElement.scrollTop || document.body.scrollTop || 0,
				left: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
			};
			window.vCenter = {
				x: window.curScroll.left + ( window.vWidth / 2 ),
				y: window.curScroll.top + ( window.vHeight / 2 ),
			};

			if( window.curScroll.top != lastScroll.top ) {
				/*** Glissement vers le haut et fade-in d'éléments ***/
				// Requiert la fonction functions.smoothFadeIn
				if( 'function' == typeof functions.smoothFadeIn ) {
					functions.smoothFadeIn();
				}

				// Mise à jour des classes active du menu de scroll
				/*if( window.pageMenu instanceof PageMenu ) {
					window.pageMenu.update();
				}*/
			}

			// Classes sur le body pour faire changer des CSS en fonction du scroll

			if( window.curScroll.top > 550 ) {
				document.body.classList.add('scrolled-a-lot');
			} else {
				document.body.classList.remove('scrolled-a-lot');
			}

			if( window.curScroll.top > 120 ) {
				document.body.classList.add('scrolled-a-bit');
			} else {
				document.body.classList.remove('scrolled-a-bit');
			}

		}, 100 );
	}) ();


	/*** Recherche qui s'ouvre/ferme en cliquant sur la loupe *********************************************/
	// Requiert toggle-search-form.js

	(function() {
		document.querySelectorAll('.search-form .search-button').forEach( function( thisEl ) {
			thisEl.addEventListener( 'click', function() {
				if( 1 == event.which ) {
					toggleSearchForm( event );
				}
			} );
		} );
	}) ();



	/*** Infinite Scroll - Charger les prochains articles automatiquement *******************************/
	// Requiert jQuery
	// Requiert infinite-scroll.pkgd.min.js

	(function() {
		let infiniteContainers = document.querySelectorAll('.infinite-scroll');

		infiniteContainers.forEach(function( thisEl ) {
			if( thisEl.parentNode.querySelector('.pagination .next') ) {
				let infiniteInstance = new InfiniteScroll(
					'.infinite-scroll',
					{
						path: '.pagination .next',
						append: '.infinite-scroll-element',
						prefill: false,
						history: false,
						// outlayer: outlayer,
						hideNav: '.pagination',
						status: '.infinite-scroll-status',
						debug: false,
					}
				);

				infiniteInstance.on( 'append', function( event, response, path, items ) {
					let loadingEl = thisEl.parentNode.querySelector('.infinite-scroll-status');

					if( loadingEl ) {
						loadingEl.style.display = 'none';
					}
					
					imgCheck();
					window.siteLazyLoad.update();
				} );
			}
		});
	}) ();


	/*** Dropdown de médias sociaux ***********************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let buttonClicked = event.target.closest('.more-networks-button');
				let theContainer;
				let theDropdown;

				if( buttonClicked ) {
					let theContainer = buttonClicked.closest( '.more-networks' );
					let theDropdown = theContainer.querySelector( '.more-networks-dropdown' );

					if( ! theContainer.classList.contains('animating') ) {
						theContainer.classList.add( 'animating' );

						if( ! theContainer.classList.contains( 'open' ) ) {
							theContainer.classList.add( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeInOver( event ) { 
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeInOver );
							} );

						} else {
							theContainer.classList.remove( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeOutOver( event ) { 
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeOutOver );
							} );
						}
					}
				}
			}
		} );
	}) ();


	/*** Sliders Glide ****************************************************************************************/
	// Requiert Glide (@glidejs/glide)
	// Ce slider n'a pas de fonction tout-en-un disponible.

	// slider specialists
	(function() {
		let linkSliders = document.querySelectorAll('.glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			if( functions.scriptsOk( sliderEl ) ) {
				let sliderOptions = {
					type: 'carousel',
					perView: 1,
					gap: 0,
					bound: false,
					rewind: true,
					autoplay: 6000,
					hoverpause: true,
				};

				let sliderInstance = new Glide( sliderEl, sliderOptions );

				sliderInstance.on( 'move', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.add( 'slider-animating' );
				} );

				sliderInstance.on( 'move.after', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.remove( 'slider-animating' );
				} );

				sliderInstance.mount();
			}
		} );
	}) ();


	/*** Sliders Owl Carousel ******************************************************************************/
	// Requiert la fonction functions.initSlider

	// (function() {
	//  	document.querySelectorAll('.slider').forEach( function( thisEl ) {
	//  		functions.initSlider( thisEl );
	//  	} );
	// }) ();


	/*** Popups AJAX qui changent l'URL ********************************************************************/
	// Requiert ajax-popups.js
	// Requiert nubee-popup.js
	// Requiert basicLightbox
	// Requiert functions.loadingStart
	// Requiert functions.loadingEnd

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest( '.open-ajax-popup' );

				if( linkClicked ) {
					let ajaxPopup;
					event.preventDefault();

					ajaxPopup = new AjaxPopup({
						elementSelector: '.ajax-popup-content',
						ajaxUrl: linkClicked.href,
						// popupClass: '',
						// debug: true,
					});
				}
			}
		} );
	}) ();


	/*** Empêcher la taille d'un élément de se réajuster une fois le chargement terminé ********************/

	(function() {
		window.addEventListener( 'load', function( event ) {
			let lockedHeightEls = document.querySelectorAll('.lock-height-on-load');

			lockedHeightEls.forEach( function( thisEl ) {
				thisEl.style.height = thisEl.offsetHeight + 'px';
				thisEl.style.minHeight = 0;
			} );
		} );
	}) ();


	/*** Polyfill pseudo-sélecteur CSS :focus-within *******************************************************/
	// Requiert focus-within
	// Il ne s'active que si le navigateur ne supporte pas le pseudo-sélecteur.
/*
	(function() {
		focusWithin.polyfill();
	}) ();
*/

	/*** Particules : Grille lignes et points animée ********************************************************/

	(function() {
		let scriptFolder = bonesGlobals.themeDir + '/library/js';

		if( document.body.classList.contains( 'page-bg-lines-animated' ) ) {
			particlesJS.load( 'particles-js', scriptFolder + '/data/particles.json', function() {
				// Callback
			});
		}
	}) ();


	/*** Select qui redirigent quand on choisit une option ***************************************************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linkSelects = document.querySelectorAll('.link-select');

		linkSelects.forEach( function( thisSelect ) {
			[ 'change', 'keypress' ].forEach( function( eventName ) {
				thisSelect.addEventListener( eventName, function() {
					let selectedOption = thisSelect.options[ thisSelect.selectedIndex ];
					let targetUrl = selectedOption.dataset.url;

					if( targetUrl && ! functions.urlsAreSamePage({ urls: targetUrl }) ) {
						window.location.href = targetUrl;
					}
				} );
			} );
		} );
	}) ();


	/*** Activation du doigt indicateur de scroll sur les blocs de statistiques appropriés *******************/
	// Requiert swipe-finger.js

	(function() {
		let fingerWrappers = document.querySelectorAll('.swipe-finger-wrapper');
		fingerWrappers.forEach( function( thisWrapper ) {
			let swipeFinger = new SwipeFinger({
				wrapper: thisWrapper,
				debug: true,
			});
		} );
	}) ();


	/*** Animation du logo **********************************************************************************/

	(function() {
		let animatedLogos = document.querySelectorAll('.animated-logo');
		animatedLogos.forEach( function( thisLogo ) {
			setTimeout( function() {
				thisLogo.classList.add('animated-step-1');

				thisLogo.addEventListener( 'transitionend', function animateStep2( event ) {
					thisLogo.removeEventListener( 'transitionend', animateStep2 );

					// Léger délai sinon les 2 étapes se font en même temps
					setTimeout( function() {
						thisLogo.classList.add('animated-step-2' );

						thisLogo.addEventListener( 'transitionend', function animateStep3( event ) {
							thisLogo.removeEventListener( 'transitionend', animateStep3 );

							// Léger délai sinon les 2 étapes se font en même temps
							setTimeout( function() {
								thisLogo.classList.add('animated-step-3' );

								thisLogo.addEventListener( 'transitionend', function animateStep4( event ) {
									thisLogo.classList.add('animated-step-4' );
									thisLogo.removeEventListener( 'transitionend', animateStep4 );
								} );
							}, 10 );
						} );
					}, 10 );
				} );
			}, 400 );
		} );
	}) ();


	/*** Lightbox et popups avec GLightbox ******************************************************************/
	// Requiert GLightbox (glightbox)
	// Requiert glightbox-nubee.js

	(function() {
		// Éléments seuls.
		let lightboxEls = document.querySelectorAll('.lightbox');
		lightboxEls.forEach( function( thisEl ) {
			new GLightboxNubee({
				links: thisEl,
			});
		} );

		// Galeries. (Attention de donner une classe différente à chaque galerie de la page.)
		// Utilisez data-lightbox-element-class="ma-classe" sur .lightbox-gallery pour l'identifier.
		let lightboxGals = document.querySelectorAll('.lightbox-gallery');
		lightboxGals.forEach( function( thisGal ) {
			new GLightboxNubee({
				galleryParent: thisGal,
				elementClass: thisGal.dataset.lightboxElementClass,
			});
		} );

		// On peut maintenant accéder aux instances à partir des liens ou du parent de la galerie :
		// thisEl._GLightboxNubee
		// thisGal._GLightbox
	}) ();


	// Fermeture du popup de pub par le X ou par le background noir
	/*(function() {
		let loadingPopupBackground = document.querySelector('.loadingPopup-background');
		let loadingPopup = document.querySelector('.loadingPopup');
		let closeLoadingPopup = document.querySelector('.close-loadingPopup');

		closeLoadingPopup.addEventListener( 'click', function() {
			if( loadingPopupBackground != null ) {
				loadingPopupBackground.classList.add('close');
			}

			loadingPopup.classList.add('close');
		} );

		if( loadingPopupBackground != null ) {
			loadingPopupBackground.addEventListener( 'click', function() {
				loadingPopupBackground.classList.add('close');
				loadingPopup.classList.add('close');
			} );
		}
	}) ();*/


	/*** Modification du font-size du site par l'utilisateur ******************************************************************/
	// Requiert functions.getCookie
	
	(function() {
		let chosenFontsize = functions.getCookie('bodyFontSize');

		let fontsizeOptionsButtons = document.querySelectorAll('.fontsize-options');
		let fontsizeLargerButtons = document.querySelectorAll('.fontsize-larger');
		let fontsizeSmallerButtons = document.querySelectorAll('.fontsize-smaller');

		let body = document.querySelector('body');
		let bodyFontSize;
		let newBodyFontSize;

		if( chosenFontsize !== false ) {
			body.style.fontSize = chosenFontsize;

			if( chosenFontsize == '17px' ) {
				fontsizeLargerButtons.forEach( function( fontsizeLargerButton ) {
					fontsizeLargerButton.classList.add('maxed');
				} );
			} else if( chosenFontsize == '14px' ) {
				fontsizeSmallerButtons.forEach( function( fontsizeSmallerButton ) {
					fontsizeSmallerButton.classList.add('maxed');
				} );
			}
		}

		fontsizeOptionsButtons.forEach( function( fontsizeOptionsButton ) {
			fontsizeOptionsButton.addEventListener( 'click', function( event ) {
				event.preventDefault();
				
				if( !this.classList.contains('open') ) {
					this.classList.add('open');
				} else {
					this.classList.remove('open');
				}
			} );
		} );

		fontsizeLargerButtons.forEach( function( fontsizeLargerButton ) {
			fontsizeLargerButton.addEventListener( 'click', function( event ) {
				event.preventDefault();
				
				bodyFontSize = parseFloat( window.getComputedStyle(body, null).getPropertyValue('font-size') );
				if( bodyFontSize < 17 ) {
					newBodyFontSize = (bodyFontSize + 1) + 'px';

					body.style.fontSize = newBodyFontSize;
					document.cookie = "bodyFontSize=" + newBodyFontSize + ";";

					fontsizeSmallerButtons.forEach( function( fontsizeSmallerButton ) {
						fontsizeSmallerButton.classList.remove('maxed');
					} );

					if( newBodyFontSize == '17px' ) {
						fontsizeLargerButtons.forEach( function( fontsizeLargerButton ) {
							fontsizeLargerButton.classList.add('maxed');
						} );
					} else {
						fontsizeLargerButtons.forEach( function( fontsizeLargerButton ) {
							fontsizeLargerButton.classList.remove('maxed');
						} );
					}
				}
			} );
		} );

		fontsizeSmallerButtons.forEach( function( fontsizeSmallerButton ) {
			fontsizeSmallerButton.addEventListener( 'click', function( event ) {
				event.preventDefault();
				
				bodyFontSize = parseFloat( window.getComputedStyle(body, null).getPropertyValue('font-size') );
				if( bodyFontSize > 14 ) {
					newBodyFontSize = (bodyFontSize - 1) + 'px';
					
					body.style.fontSize = newBodyFontSize;
					document.cookie = "bodyFontSize=" + newBodyFontSize + ";";

					fontsizeLargerButtons.forEach( function( fontsizeLargerButton ) {
						fontsizeLargerButton.classList.remove('maxed');
					} );

					if( newBodyFontSize == '14px' ) {
						fontsizeSmallerButtons.forEach( function( fontsizeSmallerButton ) {
							fontsizeSmallerButton.classList.add('maxed');
						} );
					} else {
						fontsizeSmallerButtons.forEach( function( fontsizeSmallerButton ) {
							fontsizeSmallerButton.classList.remove('maxed');
						} );
					}
				}
			} );
		} );
	}) ();

	(function() {

		if( document.body.contains( document.getElementById("other") ) ) {
			document.getElementById("other").onchange = function() {

				if(this.checked==true){
					document.getElementById("otherText").classList.add('showOther');
				}
				else {
					document.getElementById("otherText").classList.remove('showOther');
				}

			};

		}

	}) ();


	/*** Script pour fermer la fenêtre d'annonce dans le header de l'accueil *************/

	(function() {

		var closeAnnouncement = document.querySelector(".close-announcement");

		if( document.body.contains(closeAnnouncement) ) {

			closeAnnouncement.addEventListener('click', function() {

			document.querySelector(".announcement").classList.add('hide-announcement');
				
			const e = document.querySelector(".hide-announcement");
			e.addEventListener("animationend", (ev) => {
				if( ev.type === "animationend" ) {
					e.style.display = "none";
					document.querySelector('.site-header').style.top = 0;
				}
			},false);

			});

		}

	}) ();


	/*** Script pour copier l'url *************/

	(function() {
		var shareBtn = document.querySelector(".copy-url");

		if( document.body.contains(shareBtn) ) {

			shareBtn.addEventListener('click', function() {

				var dummy = document.createElement('input'),
				text = window.location.href;
				
				document.body.appendChild(dummy);
				dummy.value = text;
				dummy.select();
				document.execCommand('copy');
				document.body.removeChild(dummy);
				document.getElementById("copied-url").style.opacity = 1;
				setTimeout(function(){
					document.getElementById("copied-url").style.opacity = 0;
				}, 1000);

			});

		}

	}) ();

	/*** Gestion des préférence de cookies ***************************************/
	(function() {
		window.acceptedCookies = cookiesBar.getCookie('acceptedCookies');

		if( 'function' == typeof cookiesBar.cookieConsent ) {
			cookiesBar.cookieConsent();
		}
	}) ();

}) ();