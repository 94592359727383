import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';

function getScrollTop( args ) {
	let scrollTop;

	if( ! args || ! args.target ) {
		args = { target: args };
	}

	args.parent = args.parent || window;

	if( parent == window ) {
		scrollTop = args.target.getBoundingClientRect().top + window.curScroll.top - window.fixedElHeight - args.offset;
	} else {
		scrollTop = args.target.offsetTop + args.offset;
	}

	return scrollTop;
}

export function smoothScrollTo( args ) {
	if( ! args || ! args.target ) {
		args = { target: args };
	}

	args.parent = args.parent || window;
	args.offset = args.offset || ( args.parent == window ? 10 : 0 );

	let startEvent = new Event( 'smoothscrollstart' );
	let endEvent = new Event( 'smoothscrollend' );
	let scrollTop;
	let scrollTimeout;
	let scrollAgainTimeout;
	let origAutoHash = null;
	let ignoreRange = 10;

	if( 'string' == typeof args.target ) {
		args.target = document.querySelector( ( args.target.includes('#') ? '' : '#' ) + args.target );
	}

	if( args.target ) {
		scrollTop = getScrollTop({
			parent: args.parent,
			target: args.target,
			offset: args.offset
		});

		if( scrollTop > window.curScroll.top + ignoreRange || scrollTop < window.curScroll.top - ignoreRange ) {
			parent.dispatchEvent( startEvent );

			args.parent.scroll({
				top: scrollTop,
				behavior: 'smooth',
			});

			args.parent.addEventListener( 'scroll', function scrollListener( event ) {
				let prevScrollTop = scrollTop;

				if( scrollTimeout ) {
					clearTimeout( scrollTimeout );
				}

				if( scrollAgainTimeout ) {
					clearTimeout( scrollAgainTimeout );
				}

				// On recalcule la destination à la fin, au cas où un lazy load allongerait la page.

				scrollAgainTimeout = setTimeout( function() {
					scrollTop = getScrollTop({
						parent: args.parent,
						target: args.target,
						offset: args.offset
					});

					if( prevScrollTop != scrollTop ) {
						args.parent.scroll({
							top: scrollTop,
							behavior: 'smooth',
						});
					}
				}, 40 );

				scrollTimeout = setTimeout( function() {
					let targetId = args.target.getAttribute('id');

					if( targetId ) {
						changeHashWithoutScrolling( targetId );
					}

					// On débloque l'auto-hash qu'on a bloqué en commençant le scroll
					if( window.pageMenu ) {
						window.pageMenu.autoHash = origAutoHash;
					}

					parent.dispatchEvent( endEvent );
					parent.removeEventListener( 'scroll', scrollListener );
				}, 50 );
			} );
		}
	}
}

// Prend un d'ID #hash, et renvoie une node HTMLElement (ou alors renvoie ce qu'on a reçu si c'en est déjà un)

export function getElementByHash( selector ) {
	let element;

	if( selector ) {
		if( 'string' == typeof selector ) {
			element = document.querySelector( normalizeHash( selector ) );
		} else {
			element = selector;
		}
	} else {
		element = null;
	}

	return element;
}

export function initSlider( slider, extraOptions ) {
	slider = $(slider);
	
	let lazyLoad = slider.find('.owl-lazy').length > 0;
	let carouselOptions = {};
	let sliderObject;
	let maxItems = slider.attr('data-max-items') ? slider.attr('data-max-items') : 1;
	let nbItems = slider.find('.slider-element').length;

	extraOptions = extraOptions || {};

	if( nbItems > 1 ) {
		slider.addClass('owl-carousel').addClass('owl-theme');
		carouselOptions = {
			autoplay: slider.hasClass( 'autoplay' ),
			// autoplay: false,
			loop: ! slider.hasClass( 'no-loop' ),
			autoplayTimeout: 10000,
			singleItem: maxItems <= 1,
			nav: slider.hasClass( 'nav' ),
			dots: slider.hasClass( 'dots' ),
			touchDrag: slider.hasClass( 'drag' ),
			mouseDrag: slider.hasClass( 'drag' ),
			lazyLoad: lazyLoad,
			stagePadding : slider.hasClass( 'stage-padding' ) ? 40 : 0,
			items: 1,
			smartSpeed: 600,
			navText: [
				'<span class="icon icon-chevron-left" aria-label="' + bonesGlobals.i18n.owlCarousel.prev + '" title="' + bonesGlobals.i18n.owlCarousel.prev + '"></span>',
				'<span class="icon icon-chevron-right" aria-label="' + bonesGlobals.i18n.owlCarousel.next + '" title="' + bonesGlobals.i18n.owlCarousel.next + '"></span>',
			],
			animateOut: slider.hasClass( 'fade' ) ? 'fadeOut' : false,
			responsive: {},
		};

		if( slider.hasClass( 'stage-padding' ) ) {
			carouselOptions.responsive['500'] = { stagePadding: 80 };
		}

		if( maxItems > 1 ) {
			carouselOptions.margin = 20;
			carouselOptions.responsive['700'] = {
				singleItem: false,
				items: 2,
			};

			if( maxItems >= 3 ) {
				carouselOptions.responsive['1000'] = {
					singleItem: false,
					items: 3,
				};
			}

			if( maxItems >= 4 ) {
				carouselOptions.responsive['1200'] = {
					singleItem: false,
					items: 4,
				};
			}

			if( maxItems >= 5 ) {
				carouselOptions.responsive['1600'] = {
					singleItem: false,
					items: 5,
				};
			}
		}

		// Thumbs
		if( slider.hasClass( 'thumbs' ) ) {
			carouselOptions.onInitialized = function(event) {
				let dotsWrapper = slider.find('.owl-dots');
				let dots = dotsWrapper.children();
				let curEl = 0;

				dotsWrapper.addClass('owl-thumbs');

				slider.find('.owl-item:not(.cloned)').each(function() {
					let imgToUse = $(this).find('.slider-element').attr('data-thumb-src');

					dots.eq( curEl ).addClass('owl-thumb').append('<img class="lazy owl-thumb-image" data-src="' + imgToUse + '" />');

					curEl += 1;
				});
			};
		}


		if( Object.keys( extraOptions ).length > 0 ) {
			for( let optionName in extraOptions ) {
				carouselOptions[optionName] = extraOptions[optionName];
			}
		}

		sliderObject = slider.owlCarousel( carouselOptions );

		if( slider.hasClass('autoplay') ) {
			sliderObject.on('changed.owl.carousel', function(e) {
				sliderObject.trigger('stop.owl.autoplay');
				sliderObject.trigger('play.owl.autoplay');
			});
		}

		slider.find('.owl-next-custom').click(function() {
			sliderObject.trigger('next.owl.carousel');
		});

		slider.find('.owl-prev-custom').click(function() {
			sliderObject.trigger('prev.owl.carousel');
		});
	} else {
		slider.find('.owl-lazy')
			.removeClass('owl-lazy')
			.addClass('lazy')
		;
	}
}

// elem : Élément DOM ou objet jQuery
// offset : Nombre de pixels de grâce (plus haut = plus proche du milieu de l'écran)
// completelyInside : si true, l'élément doit être à 100% dans le viewport ; si false, il peut dépasser

export function isInViewport( elem, offset, completelyInside, parent ) {
	offset = offset || ( -1 * window.fixedElHeight );
	completelyInside = completelyInside || false;
	parent = parent || window;
	
	let condition;
	let viewportBottom;
	let viewportTop;
	let elemTop;
	let elemBottom;

	if( 'string' == typeof elem ) {
		elem = document.querySelector( elem );
	}

	if( parent == window ) {
		viewportTop = 0;
		viewportBottom = window.vHeight;
		elemTop = elem.getBoundingClientRect().top;
		elemBottom = elem.getBoundingClientRect().bottom;
	} else {
		viewportTop = parent.scrollTop;
		viewportBottom = parent.scrollTop + parent.offsetHeight;
		elemTop = elem.offsetTop;
		elemBottom = elem.offsetBottom + elem.offsetHeight;
	}


	if( completelyInside ) {
		condition = ( 
			( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
			&& ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
		);
	} else {
		condition = (
			( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
			|| ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
		);
	}

	return condition;
}


export function removeHash() {	
    history.pushState(
    	"",
    	document.title,
    	window.location.pathname + window.location.search
    );
}


export function changeHashWithoutScrolling( hash ) {
	if( hash ) {
		hash = ( hash.includes('#') ? '' : '#' ) + hash;
	}

	if( history.pushState ) {
		if( hash ) {
			history.pushState( null, null, hash );
		} else {
			//URL sans hash
			history.pushState( null, null, window.location.href.replace( window.location.hash, '' ) );
		}
	} else {
		if( hash ) {
			window.location.hash = hash;
		} else {
			removeHash();
		}
	}
}


export function smoothFadeIn(){
	document.querySelectorAll('.smooth-fade').forEach( function( thisEl ) {
		let smoothHeight = thisEl.getBoundingClientRect().top;
		let gapHeight = window.vHeight / 8;
		let finalHeight = smoothHeight - window.vHeight + gapHeight;
			
		if( curScroll > finalHeight ){
			thisEl.classList.add('visible');
		}
	} );
}

export function changeUrl( args ) {
	let returnVal;

	switch(args.context) {
		case 'open':
			window.changeUrlOriginalTitle = document.title;
			window.changeUrlOriginalUrl = window.location.href;
			window.changeUrlOriginalId = document.body.dataset.id || window.location.href;

			window.history.pushState(
				{
					id: 'popup-' + args.id
				},
				args.title,
				args.url
			);
			document.title = args.title;

			returnVal = true;
			break;

		case 'close':
			if( window.changeUrlOriginalId ) {
				window.history.pushState(
					{
						id: window.changeUrlOriginalId
					},
					window.changeUrlOriginalTitle,
					window.changeUrlOriginalUrl
				);
				document.title = changeUrlOriginalTitle;

				window.changeUrlOriginalTitle = null;
				window.changeUrlOriginalUrl = null;
				window.changeUrlOriginalId = null;

				returnVal = true;
				break;
			}
	}

	return returnVal;
}

export function loadingStart( parent ) {
	parent = parent || document.body;

	let loadingEl = parent.querySelector('.nubee-loading');

	if( ! loadingEl ) {
		let preloaderEl;
		let subLoadingEl;
		let labelEl;

		loadingEl = document.createElement( 'div' );
		loadingEl.classList.add( 'nubee-loading' );
		loadingEl.classList.add( 'admin-bar-offset' );
		subLoadingEl = document.createElement( 'div' );
		subLoadingEl.classList.add( 'nubee-loading-inner' );
		preloaderEl = document.querySelector('.footer-hidden-elements .preloader-facebook').cloneNode( true );
		preloaderEl.classList.add( 'nubee-loading-preloader' );
		labelEl = document.createElement( 'div' );
		labelEl.classList.add( 'nubee-loading-preloader' );
		labelEl.textContent = window.bonesGlobals.i18n.functions.loading;

		subLoadingEl.append( preloaderEl, labelEl );
		loadingEl.append( subLoadingEl );
		parent.append( loadingEl );
	}

	// On va chercher une valeur calculée random pour forcer le navigateur à faire la transition
	// https://stackoverflow.com/a/24195559/1995518
	loadingEl.offsetWidth;

	disableBodyScroll( loadingEl );
	document.documentElement.classList.add( 'nubee-loading-visible' );

	loadingEl.classList.add( 'visible' );
}

export function loadingEnd( parent ) {
	parent = parent || document.body;
	let loadingEl = parent.querySelector('.nubee-loading');

	enableBodyScroll( loadingEl );
	document.documentElement.classList.remove( 'nubee-loading-visible' );

	if( loadingEl ) {
		loadingEl.classList.remove( 'visible' );
	}
}

// https://stackoverflow.com/a/9310752/1995518
export function escapeRegExp(text) {
	if ( ! text || 'string' != typeof text ) {
		return '';
	}
	return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
}

export function arrayAllEqual( array ) {
	return array.every( function( elementToCheck ) {
		return elementToCheck === array[0];
	} );
}

export function urlsAreSamePage( args ) {
	let getParamsRegex = /\?.+$/;
	let trailingSlashRegexes = [
		{
			regex: /\/$/,
			replacement: '',
		},
		{
			regex: /\/\?/,
			replacement: '?',
		},
		{
			regex: /\/#/,
			replacement: '#',
		}
	];
	let nakedUrls = [];
	args = args || {};

	args.urls = args.urls || [];
	args.stripHash = args.stripHash || true;
	args.stripGet = args.stripGet || false;

	if( 'string' == typeof args.urls ) {
		args.urls = [ args.urls ];
	}

	// Une seule URL ? On compare avec celle de la page actuelle.
	if( Array.isArray( args.urls ) && 1 == args.urls.length ) {
		args.urls.push( window.location.href );
	}

	args.urls.forEach( function( thisUrl ) {
		let hashIndex = thisUrl.indexOf( '#' );
		let urlHash = -1 !== hashIndex ? thisUrl.substring( hashIndex ) : '';
		let nakedUrl = thisUrl;

		if( args.stripHash ) {
			nakedUrl = nakedUrl.replace( urlHash, '' );
		}

		if( args.stripGet ) {
			nakedUrl = nakedUrl.replace( getParamsRegex, '' );
		}

		trailingSlashRegexes.forEach( function( thisRegex ) {
			nakedUrl.replace( thisRegex.regex, thisRegex.replacement );
		} );

		nakedUrls.push( nakedUrl );
	} );
	
	return arrayAllEqual( nakedUrls );
}

export function getCookie( name ) {
	let value = "; " + document.cookie;
	let parts = value.split( "; " + name + "=" );
	
	if( ! parts.length == 2 ) {
		return false;
	}
	
	return parts.pop().split(";").shift();
}

// Cet élément fait-il partie d'un élément no-scripts? Si oui on initialisera pas toutes sortes d'affaires.

export function scriptsOk( element ) {
	return element.closest( '.no-scripts' ) ? false : true;
}