function GoogleMap( args ) {
	let that = this;
	args = args || {};

	args.center = args.center || null;
	args.zoom = args.zoom || 16;
	args.markerSel = args.markerSel || '.marker';
	args.styles = args.styles || null;
	args.navigationControl = args.navigationControl || true;
	args.mapTypeControl = args.mapTypeControl || false;
	args.zoomControl = args.zoomControl || true;
	args.streetViewControl = args.streetViewControl || false;
	args.scaleControl = args.scaleControl || false;
	args.fullscreenControl = args.fullscreenControl || false;
	args.gestureHandling = args.gestureHandling || 'auto';
	args.mapTypeId = args.mapTypeId || google.maps.MapTypeId.ROADMAP;
	args.styles = args.styles || [];
	args.markerIconUrl = args.markerIconUrl;
	args.markerIconWidth = args.markerIconWidth || 56;
	args.markerIconHeight = args.markerIconHeight || 58;

	this.args = args;

	this.init = function() {
		that.map = null;
		that.mapParent = that.args.mapParent;
		that.markerEls = that.mapParent.querySelectorAll( args.markerSel );
		that.markers = [];

		// Pas de centre par défaut ? On choisit le premier marqueur.
		if( ! that.args.center && that.markerEls.length > 0 ) {
			that.args.center = {
				lat: parseFloat( that.markerEls[0].dataset.lat ),
				lng: parseFloat( that.markerEls[0].dataset.lng ),
			};
		}

		if( that.args.center ) {
			that.args.center = new google.maps.LatLng( that.args.center );
		} else {
			that.args.center = new google.maps.latLng({ lat: 0, lng: 0 });
		}

		that.render();
		that.addMarkers();
		that.center();
	};

	this.render = function() {
		let mapArgs = {
			zoom: that.args.zoom,
			center: that.args.center,
			navigationControl: that.args.navigationControl,
			mapTypeControl: that.args.mapTypeControl,
			zoomControl: that.args.zoomControl,
			streetViewControl: that.args.streetViewControl,
			scaleControl: that.args.scaleControl,
			fullscreenControl: that.args.fullscreenControl,
			gestureHandling: that.args.gestureHandling,
			mapTypeId: that.args.mapTypeId,
			styles: that.args.styles,
		};

		that.map = new google.maps.Map( that.mapParent, mapArgs );
	};

	this.addMarkers = function() {
		that.markerEls.forEach( function( thisEl ) {
			that.addMarker( thisEl );
		} );
	};

	this.addMarker = function( markerEl ) {
		let markerArgs = {};
		let markerIconUrl;
		let markerIconWidth;
		let markerIconHeight;
		let marker;
		let iwContent = markerEl.innerHTML.trim();

		markerIconUrl = markerEl.dataset.image;
		markerIconWidth = markerEl.dataset.iconWidth || that.args.markerIconWidth;
		markerIconWidth = parseFloat( markerIconWidth );
		markerIconHeight = markerEl.dataset.iconHeight || that.args.markerIconHeight;
		markerIconHeight = parseFloat( markerIconHeight );

		markerArgs.position = new google.maps.LatLng(
			parseFloat( markerEl.dataset.lat ),
			parseFloat( markerEl.dataset.lng )
		);

		markerArgs.map = that.map;

		if( markerIconUrl ) {
			if( /\.svg(#.*)?$/i.test( markerIconUrl ) ) {
				markerArgs.icon = {
					url: markerIconUrl,
					scaledSize: new google.maps.Size( markerIconWidth, markerIconHeight ),
				};
			} else {
				markerArgs.icon = markerIconUrl;
			}
		}

		markerArgs.styles = that.args.styles || null;

		marker = new google.maps.Marker( markerArgs );

		if( iwContent ) {
			marker.infoWindow = new google.maps.InfoWindow({
				content: iwContent,
			});
			
			// marker.infoWindow.open( that.map, marker );
			
			google.maps.event.addListener( marker, 'click', function() {
				marker.infoWindow.open( that.map, marker );
			} );
		}

		that.markers.push( marker );
		return marker;
	};

	this.center = function() {
		let bounds = new google.maps.LatLngBounds();

		that.markers.forEach( function( thisMarker, i ) {
			let latLng = new google.maps.LatLng( thisMarker.position.lat(), thisMarker.position.lng() );
			bounds.extend( latLng );
		} );

		if( 1 == that.markers.length ) {
			that.map.setCenter( bounds.getCenter() );
			that.map.setZoom( that.args.zoom );
		} else {
			that.map.fitBounds( bounds );
		}
	};

	this.init();
}

export default GoogleMap;