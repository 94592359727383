import GLightbox from 'glightbox/dist/js/glightbox.js';
import { urlsAreSamePage, getElementByHash } from 'partials/functions.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';
import FormValidation from 'partials/form-validation.js';
import PrettyFileInput from 'partials/pretty-file-input.js';
import SmoothScroller from 'smooth-scroller.js';

function GLightboxNubee( args ) {
	let that = this;

	args = args || {};
	args.links = args.links || [];
	args.galleryParent = args.galleryParent || null;
	args.elementSel = args.elementSel || '.lightbox-gallery-el';
	args.gLightboxOptions = args.gLightboxOptions || {};
	that.args = args;

	that.links = [];
	that.elements = [];
	that.popupOptions = {};
	that.popupInstance = {};
	that.isGallery = false;
	that.openedOnce = false;
	that.mouseTarget = null;

	that.init = function() {
		if( that.args.debug ) {
			console.log( "glightbox-nubee: Received arguments:" );
			console.log( that.args );
		}
		
		that.prepareLinks();

		if( ! that.links.length && ! that.args.elementClass ) {
			return;
		}

		that.buildElements( that.links );
		that.isGallery = that.elements.length > 1;

		that.popupOptions = {
			// En attendant qu'ils règlent leur bug qui fait que ça commence toujours au début si on utilise elements, il faut passer par classe avec l'argument selector.
			elements: that.elements,
			autoplayVideos: true,
			keyboardNavigation: true,
			descPosition: 'top',
			skin: 'clean nubee',
		};

		// Override potentiel venu des paramètres
		Object.assign( that.popupOptions, that.args.gLightboxOptions );

		// Classe pour savoir si c'est une galerie ou pas
		that.popupOptions.skin = that.popupOptions.skin || 'nubee';

		if( that.isGallery ) {
			that.popupOptions.skin += ' gallery';
		} else {
			that.popupOptions.skin += ' single';
		}

		// Si y'a un espace au bout, GLightbox capote
		that.popupOptions.skin = that.popupOptions.skin.trim();

		that.addEventListeners();
		that.popupInstance = GLightbox( that.popupOptions );
		that.addReferencesToElements();

		if( that.args.debug ) {
			console.log( "glightbox-nubee: GLightbox initialized:" );
			console.log( that.popupInstance );
		}
	};

	that.prepareLinks = function() {
		that.links = [];

		// Si on a reçu un seul lien, on fait un array avec.
		if( that.args.links instanceof HTMLElement ) {
			that.args.links = [ that.args.links ];
		}

		// Ajout des liens envoyés en arguments à l'array vide
		that.links = that.links.concat(
			Array.from( that.args.links )
		);

		// Si c'est une galerie, on va chercher tous ses éléments
		if( that.args.galleryParent ) {
			that.links = that.links.concat(
				Array.from(
					that.args.galleryParent.querySelectorAll( that.args.elementSel )
				)
			);
		}

		if( that.args.debug ) {
			console.log( "glightbox-nubee: prepared links:" );
			console.log( that.links );
		}
	};

	that.buildElement = function( link ) {
		let newElement = null;

		if( link.href ) {
			newElement = {
				index: that.elements.length,
				link: link,
				href: link.href,
				type: 'external',
				width: '100%',
				height: '100%',
			};

			if( newElement.href.match(/\.(jpg|jpeg|gif|png|svg|webp|tif|tiff|bmp|apng|ico|cur|jfif|pjpeg|pjp)$/i) ) {
				newElement.type = 'image';
			} else if( newElement.href.match(/\.(mp4|ogg|mov|webm|avi|mkv|ogv)$/i) ) {
				newElement.type = 'video';
				newElement.source = 'local';
			} else if( newElement.href.match(/youtu/i) ) {
				newElement.type = 'video';
				newElement.source = 'youtube';
			} else if( newElement.href.match(/vimeo/i) ) {
				newElement.type = 'video';
				newElement.source = 'vimeo';
			} else if( 
				newElement.link.hash
				&& urlsAreSamePage({
					urls: [
						newElement.href,
						window.location.href,
					],
				})
			) {
				// Lien dièse (#mon-el)
				let elContent = getElementByHash( newElement.link.hash );

				if( elContent ) {
					// Le dièse fait référence à quelque chose sur cette page. On se sert de cet élément-là.
					elContent = elContent.cloneNode( true );
					newElement.content = elContent;
					newElement.type = 'inline';
					newElement.height = 'auto';
					newElement.width = 'auto';
				} else {
					// Cette ID n'exite pas. Par défaut c'est 'external'. On va essayer de charger le popup comme un AJAX.
					if( that.args.debug ) {
						console.log( "glightbox-nubee: Aucun élément trouvé sur cette page avec l'ID '" + newElement.link.hash + "'. L'élément sera initialisé comme un type external (AJAX)." );
					}
				}
			}

			// Taille pour toutes les vidéos
			if( 'video' == newElement.type ) {
				delete newElement.height;
			}
		}

		return newElement;
	};


	that.buildElements = function( links ) {
		links.forEach( function( thisLink ) {
			let newElement = that.buildElement( thisLink );
			that.elements.push( newElement );
		} );

		if( that.args.debug ) {
			console.log( "glightbox-nubee: built elements:" );
			console.log( that.elements );
		}
	};

	that.addEventListeners = function() {

		// On ouvre le popup au clic
		that.elements.forEach( function( thisEl ) {
			thisEl.link.addEventListener( 'click', function( event ) {
				event.preventDefault();

				if( that.args.debug ) {
					console.log( "glightbox-nubee: opening element :" );
					console.log( thisEl );
				}

				that.popupInstance.openAt( thisEl.index );
			} );
		} );

		// Enregistrement d'éventuelles fonctions personnalisées ajoutées dans les paramètres pour les exécuter après ce code.
		let customOnOpen = that.popupOptions.onOpen || null;
		let customOnClose = that.popupOptions.onClose || null;

		// Évènements à l'ouverture du popup
		that.popupOptions.onOpen = function() {
			let popupBlock = that.popupInstance.modal || null;

			// Blocage du scroll
			disableBodyScroll( popupBlock, { reserveScrollBarGap: true } );

			// Fermeture si on clique en-dehors de l'image
			if( ! that.openedOnce ) {
				if( popupBlock ) {
					// On enregistre la cible, pour différentier un vrai clic d'un drag
					popupBlock.addEventListener( 'mousedown', function( event ) {
						that.mouseTarget = event.target;
					} );

					// Clic sur le background = fermer le popup
					popupBlock.addEventListener( 'click', function( event ) {
						if(
							that.mouseTarget
							&& that.mouseTarget == event.target
							&& event.target.matches( '.ginner-container' )
						) {
							that.popupInstance.close();
						}

						that.mouseTarget = null;
					} );


					/*** Initialisation des scripts sur le contenu de la popup ************************************/

					/*(function() {
						popupBlock.querySelectorAll( '.gslide-media' ).forEach( function( thisSlide ) {
							// Smooth scroller
							thisSlide.smoothScroller = new SmoothScroller({
								parent: thisSlide,
								// debug: that.args.debug,
							});

							// Validation de formulaire
							thisSlide.querySelectorAll( '.validate-form' ).forEach( function( thisForm ) {
								// Validation du formulaire
								new FormValidation({
									form: thisForm,
									smoothScroller: thisSlide.smoothScroller,
									// debug: that.args.debug,
								});
							} );

							// pretty-file-input
							let inputs = popupBlock.querySelectorAll( '.hidden-file-input' );
							inputs.forEach( function( thisEl ) {
								new PrettyFileInput({
									input: thisEl,
									// debug: that.args.debug,
								});
							});
						} );
					}) ();*/
				}

				that.popupInstance.openedOnce = true;
			}

			if( customOnOpen ) {
				customOnOpen();
			}
		};

		// Évènements à la fermeture du popup
		that.popupOptions.onClose = function() {
			let popupBlock = that.popupInstance.modal || null;
			// Réactivation du scroll
			enableBodyScroll( popupBlock, { reserveScrollBarGap: true } );

			if( customOnClose ) {
				customOnClose();
			}
		}; 
	};

	// Permet par la suite d'accéder au popup à partir de n'importe quel élément <a> ou du parent de la galerie
	that.addReferencesToElements = function() {
		let elements = that.links;
		
		if( that.args.galleryParent ) {
			elements.push( that.args.galleryParent );
		}

		elements.forEach( function( thisLink ) {
			thisLink._GLightboxNubee = that;
			thisLink._GLightbox = that.popupInstance;
		} );
	};

	that.init();
}

export default GLightboxNubee;