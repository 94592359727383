/*** Gestion des préférence de cookies ***************************************/
export function cookieConsent() {
	let cookieConsent = document.getElementById('cookie-consent'),
		cookieConsentBar = cookieConsent.querySelector('.bar'),
		cookieConsentDetails = cookieConsent.querySelector('.details'),

		cookieBtnCustomize = cookieConsentBar.querySelector('#cookies-customize'),
		cookieBtnCustomizeState = cookieBtnCustomize.querySelector('.symbol'),
		cookieBtnAccept = cookieConsentBar.querySelector('#cookies-accept'),
		cookieBtnReject = cookieConsentBar.querySelector('#cookies-reject'),
		cookieBtnSave = document.getElementById('cookies-save'),

		cookieSwitchNecessary = cookieConsentDetails.querySelector('#toggleSwitchNecessary'),
		cookieSwitchFunctional = cookieConsentDetails.querySelector('#toggleSwitchFunctional'),
		cookieSwitchAnalytics = cookieConsentDetails.querySelector('#toggleSwitchAnalytics'),
		cookieSwitchAdvertissement = cookieConsentDetails.querySelector('#toggleSwitchAdvertissement'),

		cookiePreferences = {},

		gtag_consent = {};

	// Si on a pas encore choisi ses préférences de cookies, on affiche la bance
	if( window.acceptedCookies === '' ) {
		cookieConsent.classList.remove('hidden');
		cookieConsent.classList.add('closed');

		let translateValue = cookieConsent.clientHeight - cookieConsentBar.clientHeight;

		cookieConsent.style.transform = 'translateY( ' + translateValue + 'px )';

		setTimeout( function() {
			if( translateValue != ( cookieConsent.clientHeight - cookieConsentBar.clientHeight ) ) {
				cookieConsent.style.transform = 'translateY( ' + ( cookieConsent.clientHeight - cookieConsentBar.clientHeight ) + 'px )';
			}
		}, 500 )

	// Sinon, on va mettre les switch aux bonnes valeurs au cas où l'utilisateur réouvrirais la boite
	} else {
		cookiePreferences = JSON.parse( window.acceptedCookies );

		if( cookieSwitchFunctional !== null ) {
			cookieSwitchFunctional.checked = cookiePreferences.functional;
		}

		if( cookieSwitchAnalytics !== null ) {
			cookieSwitchAnalytics.checked = cookiePreferences.analytics;

			gtag_consent.analytics_storage = cookieSwitchAnalytics.checked ? 'granted' : 'denied';
		}

		if( cookieSwitchAdvertissement !== null ) {
			cookieSwitchAdvertissement.checked = cookiePreferences.advertissement;

			gtag_consent.ad_storage = cookieSwitchAdvertissement.checked ? 'granted' : 'denied';
		}

		if( bonesGlobals.gsk_analytics_id !== '' ) { gtag( 'consent', 'update', gtag_consent ); }
	}

	// Si on resize on ajuste le translate pour voir juste la bande par défaut
	window.addEventListener( 'resize', function() {
		if( window.acceptedCookies === '' && cookieConsent.classList.contains('closed') ) {
			cookieConsent.style.transform = 'translateY( ' + ( cookieConsent.clientHeight - cookieConsentBar.clientHeight ) + 'px )';
		}
	});

	// Réouvrir la bande si on clique sur le lien du footer
	if( document.getElementById('open-cookie-bar') ) {
		document.getElementById('open-cookie-bar').addEventListener('click', function(e){
			e.preventDefault();

			cookieConsent.classList.remove('hidden');
			cookieConsent.classList.add('closed');

			cookieConsent.style.transform = 'translateY( ' + ( cookieConsent.clientHeight - cookieConsentBar.clientHeight ) + 'px )';
			cookieBtnCustomizeState.textContent = '+';
		});
	}

	// Ouvrir les préférences personnalisé
	cookieBtnCustomize.addEventListener('click', function(e){
		e.preventDefault();

		if( cookieConsent.classList.contains('closed') ) {
			cookieConsent.style.transform = 'translateY( 0px )';
			cookieBtnCustomizeState.textContent = '-';
		} else {
			cookieConsent.style.transform = 'translateY( ' + ( cookieConsent.clientHeight - cookieConsentBar.clientHeight ) + 'px )';
			cookieBtnCustomizeState.textContent = '+';
		}

		cookieConsent.classList.toggle('closed');
	});

	// On va enregistrer les préférences de l'utilisateur
	cookieBtnSave.addEventListener('click', function(e){
		e.preventDefault();

		let needRefresh = false;

		if( cookieSwitchFunctional !== null ) {
			cookiePreferences.functional = cookieSwitchFunctional.checked;

			needRefresh = true;
		}

		if( cookieSwitchAnalytics !== null ) {
			cookiePreferences.analytics = cookieSwitchAnalytics.checked;
			needRefresh = true;

			gtag_consent.analytics_storage = cookieSwitchAnalytics.checked ? 'granted' : 'denied';
		}

		if( cookieSwitchAdvertissement !== null ) {
			cookiePreferences.advertissement = cookieSwitchAdvertissement.checked;
			needRefresh = true;

			gtag_consent.ad_storage = cookieSwitchAdvertissement.checked ? 'granted' : 'denied';
		}

		document.cookie = "acceptedCookies=" + JSON.stringify( cookiePreferences ) + "; max-age=2592000; path=/";

		cookieConsent.classList.remove('closed');
		cookieConsent.classList.add('hidden');


		if( bonesGlobals.gsk_analytics_id !== '' ) { gtag( 'consent', 'update', gtag_consent ); }
		// if( needRefresh ) { location.reload( true ); }
	});

	// On accepte tous les cookies
	cookieBtnAccept.addEventListener('click', function(e){
		e.preventDefault();

		let needRefresh = false;

		if( cookieSwitchFunctional !== null ) {
			cookiePreferences.functional = true;
			needRefresh = true;
		}

		if( cookieSwitchAnalytics !== null ) {
			cookiePreferences.analytics = true;
			needRefresh = true;

			gtag_consent.analytics_storage = 'granted';
		}

		if( cookieSwitchAdvertissement !== null ) {
			cookiePreferences.advertissement = true;
			needRefresh = true;

			gtag_consent.ad_storage = 'granted';
		}

		document.cookie = "acceptedCookies=" + JSON.stringify( cookiePreferences ) + "; max-age=2592000; path=/";

		cookieConsent.classList.remove('closed');
		cookieConsent.classList.add('hidden');

		if( bonesGlobals.gsk_analytics_id !== '' ) { gtag( 'consent', 'update', gtag_consent ); }
		// if( needRefresh ) { location.reload( true ); }
	});

	// On refuse tous les cookies
	cookieBtnReject.addEventListener('click', function(e){
		e.preventDefault();

		let needRefresh = false;

		if( cookieSwitchFunctional !== null ) {
			cookiePreferences.functional = false;
			needRefresh = true;
		}

		if( cookieSwitchAnalytics !== null ) {
			cookiePreferences.analytics = false;
			needRefresh = true;

			gtag_consent.analytics_storage = 'denied';
		}

		if( cookieSwitchAdvertissement !== null ) {
			cookiePreferences.advertissement = false;
			needRefresh = true;

			gtag_consent.ad_storage = 'denied';
		}

		document.cookie = "acceptedCookies=" + JSON.stringify( cookiePreferences ) + "; max-age=2592000; path=/";

		cookieConsent.classList.remove('closed');
		cookieConsent.classList.add('hidden');

		if( bonesGlobals.gsk_analytics_id !== '' ) { gtag( 'consent', 'update', gtag_consent ); }
		// if( needRefresh ) { location.reload( true ); }
	});
}

export function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

// export default cookieConsent;
// export default getCookie;